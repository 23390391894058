import React from "react"

const LayoutCache = ({}) => (
  <>
    <section id="gallery-section">
      <div className="container">
        <div className="row">
          <div className="col-md-offset-2 col-md-8 text-center">
            <div className="section-title animation fadeInUp">
              <h2>
                OUR <img src="images/heart-icon.png" alt="" /> GALLERY
              </h2>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ultrices malesuada ante quis pharetra. Nullam notn bibendum
                dolor. Ut vel turpis accumsan, efficitur dolor fermentum,
                tincidunt metus. Etiam ut ultrices nibh. Etiam aliquam mauris
                non hendrerit faucibus. Proin pulvinar congue ex, vitae
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/500x750"
                      className="magnific-zoom-gallery"
                      title="Attachment-1"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x394"
                      className="magnific-zoom-gallery"
                      title="Attachment-2"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-3"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-4"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-5"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-6"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-7"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-8"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="photo-item frame-border animation fadeIn">
              <img
                src="http://placehold.it/290x290"
                alt=""
                className="hover-animation image-zoom-rotate"
              />

              <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

              <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                <div className="alignment">
                  <div className="v-align center-middle">
                    <a
                      href="http://placehold.it/700x467"
                      className="magnific-zoom-gallery"
                      title="Attachment-9"
                    >
                      <div className="de-icon circle light medium-size inline">
                        <i className="de-icon-zoom-in"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="dont-miss-it">
      <div
        className="image-divider auto-height"
        style={{ backgroundImage: "url(http://placehold.it/1920x1080)" }}
        data-stellar-background-ratio="0.3"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <h2
                id="dont-miss-it-title"
                className="text-center animation fadeInUp"
              >
                Don't Miss It!
              </h2>

              <div className="text-center animation fadeInUp">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer ultrices malesuada ante quis pharetra. Nullam non
                  bibendum dolor. Ut vel turpis accumsan, efficitur dolor
                  fermentum, tincidunt metus. Etiam ut ultrices nibh. Etiam
                  aliquam mauris non hendrerit faucibus. Proin pulvinar congue
                  ex, vitae commodo. Phasellus condimentum, mi ut sodales
                  gravida.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-offset-3 col-md-6">
              <div
                id="date-countdown"
                className="circle-countdown animation fadeInUp"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="more-events">
      <div className="container">
        <div className="row">
          <div className="col-md-offset-2 col-md-8 text-center">
            <div className="section-title animation fadeInUp">
              <h2>
                MORE <img src="images/heart-icon.png" alt="" /> EVENTS
              </h2>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ultrices malesuada ante quis pharetra. Nullam notn bibendum
                dolor. Ut vel turpis accumsan, efficitur dolor fermentum,
                tincidunt metus. Etiam ut ultrices nibh. Etiam aliquam mauris
                non hendrerit faucibus. Proin pulvinar congue ex, vitae
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div id="events-carousel" className="owl-carousel">
              <div className="item">
                <div className="photo-item animation fadeIn">
                  <img
                    src="http://placehold.it/800x533"
                    alt=""
                    className="hover-animation image-zoom-in"
                  />

                  <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                  <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                    <div className="alignment">
                      <div className="v-align center-middle">
                        <a
                          href="http://placehold.it/800x533"
                          className="magnific-zoom"
                          title="event-1"
                        >
                          <div className="de-icon circle light medium-size inline">
                            <i className="de-icon-zoom-in"></i>
                          </div>
                        </a>

                        <a href="#">
                          <div className="de-icon circle light medium-size inline">
                            <i className="de-icon-link"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="title-excerpt animation fadeIn">
                  <h3>
                    <a href="#">DANCING PARTY</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce urna urna, imperdiet et purus ut, ultricies
                    sollicitudin arcu. Cras placerat, arcu non faucibus
                    suscipit, ex arcu consectetur enim.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="photo-item animation fadeIn">
                  <img
                    src="http://placehold.it/800x533"
                    alt=""
                    className="hover-animation image-zoom-in"
                  />

                  <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                  <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                    <div className="alignment">
                      <div className="v-align center-middle">
                        <a
                          href="http://placehold.it/800x533"
                          className="magnific-zoom"
                          title="event-2"
                        >
                          <div className="de-icon circle light medium-size inline">
                            <i className="de-icon-zoom-in"></i>
                          </div>
                        </a>

                        <a href="#">
                          <div className="de-icon circle light medium-size inline">
                            <i className="de-icon-link"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="title-excerpt animation fadeIn">
                  <h3>
                    <a href="#">FLOWER AND FLOWER</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce urna urna, imperdiet et purus ut, ultricies
                    sollicitudin arcu. Cras placerat, arcu non faucibus
                    suscipit, ex arcu consectetur enim.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="photo-item animation fadeIn">
                  <img
                    src="http://placehold.it/800x533"
                    alt=""
                    className="hover-animation image-zoom-in"
                  />

                  <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                  <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                    <div className="alignment">
                      <div className="v-align center-middle">
                        <a
                          href="http://placehold.it/800x533"
                          className="magnific-zoom"
                          title="event-3"
                        >
                          <div className="de-icon circle light medium-size inline">
                            <i className="de-icon-zoom-in"></i>
                          </div>
                        </a>

                        <a href="#">
                          <div className="de-icon circle light medium-size inline">
                            <i className="de-icon-link"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="title-excerpt animation fadeIn">
                  <h3>
                    <a href="#">FOOD TRUCK DINNER</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce urna urna, imperdiet et purus ut, ultricies
                    sollicitudin arcu. Cras placerat, arcu non faucibus
                    suscipit, ex arcu consectetur enim.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="photo-divider-2">
      <div
        className="image-divider fixed-height"
        style={{
          backgroundImage: "url(http://placehold.it/1920x1080)",
          height: "450px",
        }}
        data-stellar-background-ratio="0.3"
      ></div>
    </section>

    <section id="groomsmen-section">
      <div className="container">
        <div className="row">
          <div className="col-md-offset-2 col-md-8 text-center">
            <div className="section-title animation fadeInUp">
              <h2>GROOMSMEN</h2>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ultrices malesuada ante quis pharetra. Nullam notn bibendum
                dolor. Ut vel turpis accumsan, efficitur dolor fermentum,
                tincidunt metus. Etiam ut ultrices nibh. Etiam aliquam mauris
                non hendrerit faucibus. Proin pulvinar congue ex, vitae
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="groomsmen-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">BEST MAN</a>
                </h3>
                <p>James Nathan</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="groomsmen-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">BEST FRIEND</a>
                </h3>
                <p>Jacob</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="groomsmen-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">ROOM MATE</a>
                </h3>
                <p>Ehtan Henry</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="groomsmen-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">COLLEGE FRIEND</a>
                </h3>
                <p>William Chloe</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="groomsmen-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">BROTHER</a>
                </h3>
                <p>Samuel Luke</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="groomsmen-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">FUTURE BROTHER IN LAW</a>
                </h3>
                <p>Hudson</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="photo-divider-3">
      <div
        className="image-divider fixed-height"
        style={{ backgroundImage: "url(http://placehold.it/1920x1080)" }}
        data-stellar-background-ratio="0.3"
      ></div>
    </section>

    <section id="bridesmaid-section">
      <div className="container">
        <div className="row">
          <div className="col-md-offset-2 col-md-8 text-center">
            <div className="section-title animation fadeInUp">
              <h2>BRIDESMAID</h2>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ultrices malesuada ante quis pharetra. Nullam notn bibendum
                dolor. Ut vel turpis accumsan, efficitur dolor fermentum,
                tincidunt metus. Etiam ut ultrices nibh. Etiam aliquam mauris
                non hendrerit faucibus. Proin pulvinar congue ex, vitae
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="bridesmaid-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">MAID OF HONOR</a>
                </h3>
                <p>Emma Grace</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bridesmaid-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">BEST FRIEND</a>
                </h3>
                <p>Audrey Amelia</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bridesmaid-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">ROOM MATE</a>
                </h3>
                <p>Abigail Chloe</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bridesmaid-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">COLLEGE FRIEND</a>
                </h3>
                <p>Clara Natalie</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bridesmaid-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">SISTER</a>
                </h3>
                <p>Alice Stella</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bridesmaid-item">
              <div className="photo-item frame-border animation fadeIn">
                <img
                  src="http://placehold.it/293x325"
                  alt=""
                  className="hover-animation image-zoom-rotate"
                />

                <div className="layer wh100 hidden-black-overlay hover-animation fade-in"></div>

                <div className="layer wh100 hidden-link hover-animation delay1 fade-in">
                  <div className="alignment">
                    <div className="v-align center-middle">
                      <a
                        href="http://placehold.it/293x325"
                        className="magnific-zoom"
                      >
                        <div className="de-icon circle light medium-size inline">
                          <i className="de-icon-zoom-in"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-excerpt animation fadeIn text-center">
                <h3 className="no-margin">
                  <a href="#">FUTURE SISTER IN LAW</a>
                </h3>
                <p>Lucy Evelyn</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="gift-section">
      <div className="container">
        <div className="row">
          <div className="col-md-offset-2 col-md-8 text-center">
            <div className="section-title animation fadeInUp">
              <h2>GIFT REGISTRY</h2>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ultrices malesuada ante quis pharetra. Nullam notn bibendum
                dolor. Ut vel turpis accumsan, efficitur dolor fermentum,
                tincidunt metus. Etiam ut ultrices nibh. Etiam aliquam mauris
                non hendrerit faucibus. Proin pulvinar congue ex, vitae
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-offset-1 col-md-10 text-center">
            <div id="registry-logo" className="owl-carousel">
              <div className="item">
                <a href="#">
                  <img src="http://placehold.it/249x67" alt="" />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img src="http://placehold.it/249x67" alt="" />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img src="http://placehold.it/249x67" alt="" />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img src="http://placehold.it/249x67g" alt="" />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img src="http://placehold.it/249x67" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

LayoutCache.propTypes = {}

LayoutCache.defaultProps = {}

export default LayoutCache
